/**

Variables
===

**/
/***

Colors
===
Here are the colour combinations used by the site:

*Accessibility tip: Contrast ratio:*

Don't forget to run colour combinations of background color and text color
against a [contrast ratio checker](http://webaim.org/resources/contrastchecker/).
If the contrast ratio is too low, people _will not be able to read it_.

* WCAG level AA requires a contrast ratio of 4.5:1 for normal text and 3:1 for large text
* WCAG Level AAA requires a contrast ratio of 7:1 for normal text and 4.5:1 for large text

_Large text is defined as 18px+ (if bold) and or 24px+ if not bold._

  ```
  <div class="color-box font-smallprint" style="background-color: #dcdcdc; border-color: #000">Background: #dcdcdc<br>Foreground: #282828<br>Contrast Ratio: 10.75:1</div>
  <div class="color-box font-smallprint" style="background-color: #8c8c8c; color: #fff">Background: #8c8c8c<br>Foreground: #ffffff<br>Contrast Ratio: 3.36:1</div>
  <div class="color-box font-smallprint" style="background-color: #282828; color: #ffffff;">Background: #282828<br>Foreground: #ffffff<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint" style="background-color: #6e6e6e; color: #ffffff;">Background: #6e6e6e<br>Foreground: #ffffff<br>Contrast Ratio: 5.1:1</div>
  <div class="color-box font-smallprint" style="background-color: #f0f0f0;">Background: #f0f0f0<br>Foreground: #282828<br>Contrast Ratio: 12.94:1</div>
  <div class="color-box font-smallprint" style="background-color: #fafafa;">Background: #fafafa<br>Foreground: #282828<br>Contrast Ratio: 14.13:1</div>
  <div class="color-box font-smallprint" style="background-color: #D64220; color: #ffffff">Background: #D64220<br>Foreground: #ffffff<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint" style="background-color: #000000; color: #ffffff;">Background: #000000<br>Foreground: #ffffff<br>Contrast Ratio: 21:1</div>
  <div class="color-box font-smallprint" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #282828<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint color-brand" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #D64220<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint color-light" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #8c8c8c<br>Contrast Ratio: 3.36:1</div>
  ```
***/
.color-box {
  align-items: center;
  border: 2px solid #dcdcdc;
  display: flex;
  float: left;
  height: 100px;
  justify-content: center;
  margin: 0 20px 20px 0;
  padding: 0 20px;
  width: calc(33.33% - 20px); }

.color-brand {
  color: #d64220; }

.color-light {
  color: #8c8c8c; }

/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/* 1 */
/* 1 */
/* 1 */
/* 1 */
/**

Variables
===

**/
/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
.search {
  position: relative;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 40px 30px 40px;
  background-color: #f7f7f7;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dcdcdc; }
  @media print, screen and (min-width: 650px) {
    .search {
      padding: 30px 40px; } }
  @media print, screen and (min-width: 990px) {
    .search {
      border-left: 1px solid #dcdcdc;
      border-right: 1px solid #dcdcdc;
      overflow: initial; } }
  @media screen and (max-width: 989px) {
    .search--active {
      overflow-y: hidden; } }
  .search__searchbar {
    display: grid;
    grid-template-columns: calc(100% - 115px) 60px; }
    @media screen and (max-width: 649px) {
      .search__searchbar {
        grid-template-columns: none; } }
  .search__input-group {
    position: relative;
    width: 100%; }
  .search__input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border-radius: 0 !important;
    margin-right: 20px;
    border: 2px solid #dcdcdc;
    width: calc(100% - 25px);
    color: #6e6e6e;
    display: block;
    font-family: "Calibre", sans-serif;
    font-size: 22px;
    height: 60px;
    padding: 0 22px; }
    .search__input::placeholder {
      color: #b4b4b4; }
    .search__input:focus {
      color: #000000; }
    @media screen and (max-width: 649px) {
      .search__input {
        width: 100%; } }
  .search__button {
    font-size: 18px;
    width: 60px; }
    @media screen and (max-width: 649px) {
      .search__button {
        display: none; } }
    .search__button:focus {
      color: #fff;
      border: 1px solid #b3371b; }
  .search__header {
    text-align: left;
    padding: 15px 0; }
    @media print, screen and (min-width: 650px) {
      .search__header {
        display: none; } }

.search-results {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  text-align: left;
  font-family: 'Calibre';
  font-size: 30px; }
  @media print, screen and (min-width: 990px) {
    .search-results {
      padding-top: 50px; } }
  @media screen and (max-width: 989px) {
    .search-results {
      font-size: inherit; } }
  .search-results p {
    margin-top: 0; }

/***
search-suggestion
======
* Class name: `-m-search-suggestion`
* Modifier classes: `-m-search-suggestion--`
* Tags used on: `<div>`
***/
.m-search-suggestion {
  position: relative;
  background: #fff;
  border-top: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  box-shadow: none;
  color: #8c8c8c;
  font-size: 16px;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  position: relative;
  width: 100%; }
  .m-search-suggestion:first-of-type {
    border-top: none; }
  .m-search-suggestion:last-of-type {
    background: #d64220;
    color: #fff; }
  .m-search-suggestion__icon {
    margin-top: 5px;
    margin-right: 15px; }
  .m-search-suggestion__search-all {
    position: absolute;
    bottom: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media print, screen and (min-width: 650px) {
      .m-search-suggestion__search-all {
        bottom: 22px; } }
  .m-search-suggestion strong {
    color: #000;
    font-weight: normal; }
  @media print, screen and (min-width: 650px) {
    .m-search-suggestion {
      font-size: 20px;
      padding: 15px 20px; } }
  .m-search-suggestion:hover {
    background: #f7f7f7; }
    .m-search-suggestion:hover:last-of-type {
      background: #b3371b; }

.suggestions {
  position: absolute;
  z-index: 3;
  left: 0;
  box-shadow: 0px 1px 4px #dcdcdc;
  width: calc(100% - 25px);
  text-align: left; }
  @media screen and (max-width: 649px) {
    .suggestions {
      width: 100%; } }
