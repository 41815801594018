/**

Variables
===

**/
/***

Colors
===
Here are the colour combinations used by the site:

*Accessibility tip: Contrast ratio:*

Don't forget to run colour combinations of background color and text color
against a [contrast ratio checker](http://webaim.org/resources/contrastchecker/).
If the contrast ratio is too low, people _will not be able to read it_.

* WCAG level AA requires a contrast ratio of 4.5:1 for normal text and 3:1 for large text
* WCAG Level AAA requires a contrast ratio of 7:1 for normal text and 4.5:1 for large text

_Large text is defined as 18px+ (if bold) and or 24px+ if not bold._

  ```
  <div class="color-box font-smallprint" style="background-color: #dcdcdc; border-color: #000">Background: #dcdcdc<br>Foreground: #282828<br>Contrast Ratio: 10.75:1</div>
  <div class="color-box font-smallprint" style="background-color: #8c8c8c; color: #fff">Background: #8c8c8c<br>Foreground: #ffffff<br>Contrast Ratio: 3.36:1</div>
  <div class="color-box font-smallprint" style="background-color: #282828; color: #ffffff;">Background: #282828<br>Foreground: #ffffff<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint" style="background-color: #6e6e6e; color: #ffffff;">Background: #6e6e6e<br>Foreground: #ffffff<br>Contrast Ratio: 5.1:1</div>
  <div class="color-box font-smallprint" style="background-color: #f0f0f0;">Background: #f0f0f0<br>Foreground: #282828<br>Contrast Ratio: 12.94:1</div>
  <div class="color-box font-smallprint" style="background-color: #fafafa;">Background: #fafafa<br>Foreground: #282828<br>Contrast Ratio: 14.13:1</div>
  <div class="color-box font-smallprint" style="background-color: #D64220; color: #ffffff">Background: #D64220<br>Foreground: #ffffff<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint" style="background-color: #000000; color: #ffffff;">Background: #000000<br>Foreground: #ffffff<br>Contrast Ratio: 21:1</div>
  <div class="color-box font-smallprint" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #282828<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint color-brand" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #D64220<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint color-light" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #8c8c8c<br>Contrast Ratio: 3.36:1</div>
  ```
***/
.color-box {
  align-items: center;
  border: 2px solid #dcdcdc;
  display: flex;
  float: left;
  height: 100px;
  justify-content: center;
  margin: 0 20px 20px 0;
  padding: 0 20px;
  width: calc(33.33% - 20px); }

.color-brand {
  color: #d64220; }

.color-light {
  color: #8c8c8c; }

/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/* 1 */
/* 1 */
/* 1 */
/* 1 */
/**

Variables
===

**/
/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
.g-nav-overlay {
  background-color: black;
  opacity: 0;
  transition: opacity 0.5s ease; }
  .g-nav-overlay--transition {
    width: 100%; }
  .g-nav-overlay--active {
    cursor: default;
    overflow: hidden;
    height: 100vh;
    left: 0;
    opacity: 0.2;
    position: fixed;
    top: 0;
    z-index: 6; }
    @media print {
      .g-nav-overlay--active {
        display: none; } }

.side-menu {
  font-weight: normal;
  /**
    * Overwrite how .g-nav class behaves from components-barnes-toolkit/scss/state/_nav-active.scss;
    */ }
  .side-menu--active .g-nav__inner {
    overflow-y: scroll; }
    .side-menu--active .g-nav__inner::-webkit-scrollbar {
      width: 22px; }
    .side-menu--active .g-nav__inner::-webkit-scrollbar-thumb {
      border-radius: 10px;
      min-height: 120px;
      background-color: #dcdcdc;
      border: solid 8px white; }
  .side-menu__header {
    padding-top: 24px;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold; }
    @media print, screen and (min-width: 650px) {
      .side-menu__header {
        font-size: 32px;
        line-height: 40px; } }
    @media print, screen and (min-width: 990px) {
      .side-menu__header {
        font-size: 38px;
        line-height: 50px; } }
  .side-menu__radio-selection-container {
    padding: 18px 0;
    margin: 0 0 24px 0;
    display: grid;
    grid-template-columns: repeat(2, 140px); }
    .side-menu__radio-selection-container:hover {
      cursor: pointer; }
  .side-menu__radio-container {
    position: relative; }
  .side-menu__radio {
    display: none; }
    .side-menu__radio:checked {
      background-color: #d64220; }
  .side-menu__radio-text {
    position: absolute; }
    .side-menu__radio-text:hover {
      color: #d64220; }
    .side-menu__radio-text:before {
      content: " ";
      cursor: pointer;
      display: inline-block;
      position: relative;
      top: 1px;
      margin: 0 8px 0 0;
      width: 12px;
      height: 12px;
      border-radius: 11px;
      border: 1px solid #b4b4b4;
      background-color: transparent; }
    .side-menu__radio-text:hover:before {
      content: " ";
      cursor: pointer;
      display: inline-block;
      position: relative;
      top: 1px;
      margin: 0 8px 0 0;
      width: 12px;
      height: 12px;
      border-radius: 11px;
      border: 1px solid #d64220;
      background-color: transparent; }
    .side-menu__radio-text--active:before {
      content: " ";
      cursor: pointer;
      display: inline-block;
      position: relative;
      top: 1px;
      margin: 0 8px 0 0;
      width: 12px;
      height: 12px;
      border-radius: 11px;
      border: 4px solid #d64220;
      background-color: #ffffff; }
    .side-menu__radio-text--active:hover:before {
      content: " ";
      cursor: pointer;
      display: inline-block;
      position: relative;
      top: 1px;
      margin: 0 8px 0 0;
      width: 12px;
      height: 12px;
      border-radius: 11px;
      border: 4px solid #d64220;
      background-color: #ffffff; }
  .side-menu__artist {
    line-height: 24px; }
    .side-menu__artist:hover {
      cursor: pointer; }
  .side-menu__artist-doc-count {
    color: #6e6e6e; }

.g-nav {
  z-index: 8; }
  .g-nav--active {
    transform: translate3d(-100%, 0, 0); }
  .g-nav--custom {
    cursor: default;
    color: #000000; }
    .g-nav--custom .g-nav__inner {
      cursor: default;
      background-color: #ffffff; }
    .g-nav--custom .g-nav-overlay {
      cursor: default; }
  .g-nav--main .g-nav__link:focus {
    outline-color: #ffffff !important; }
  .g-nav--main .g-nav__close {
    color: #ffffff !important; }
    .g-nav--main .g-nav__close:hover, .g-nav--main .g-nav__close:focus {
      outline-color: #ffffff !important; }
      .g-nav--main .g-nav__close:hover svg, .g-nav--main .g-nav__close:focus svg {
        color: #e68e79 !important; }
  .g-nav__close {
    top: 0 !important; }
