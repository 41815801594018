/**

Variables
===

**/
/***

Colors
===
Here are the colour combinations used by the site:

*Accessibility tip: Contrast ratio:*

Don't forget to run colour combinations of background color and text color
against a [contrast ratio checker](http://webaim.org/resources/contrastchecker/).
If the contrast ratio is too low, people _will not be able to read it_.

* WCAG level AA requires a contrast ratio of 4.5:1 for normal text and 3:1 for large text
* WCAG Level AAA requires a contrast ratio of 7:1 for normal text and 4.5:1 for large text

_Large text is defined as 18px+ (if bold) and or 24px+ if not bold._

  ```
  <div class="color-box font-smallprint" style="background-color: #dcdcdc; border-color: #000">Background: #dcdcdc<br>Foreground: #282828<br>Contrast Ratio: 10.75:1</div>
  <div class="color-box font-smallprint" style="background-color: #8c8c8c; color: #fff">Background: #8c8c8c<br>Foreground: #ffffff<br>Contrast Ratio: 3.36:1</div>
  <div class="color-box font-smallprint" style="background-color: #282828; color: #ffffff;">Background: #282828<br>Foreground: #ffffff<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint" style="background-color: #6e6e6e; color: #ffffff;">Background: #6e6e6e<br>Foreground: #ffffff<br>Contrast Ratio: 5.1:1</div>
  <div class="color-box font-smallprint" style="background-color: #f0f0f0;">Background: #f0f0f0<br>Foreground: #282828<br>Contrast Ratio: 12.94:1</div>
  <div class="color-box font-smallprint" style="background-color: #fafafa;">Background: #fafafa<br>Foreground: #282828<br>Contrast Ratio: 14.13:1</div>
  <div class="color-box font-smallprint" style="background-color: #D64220; color: #ffffff">Background: #D64220<br>Foreground: #ffffff<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint" style="background-color: #000000; color: #ffffff;">Background: #000000<br>Foreground: #ffffff<br>Contrast Ratio: 21:1</div>
  <div class="color-box font-smallprint" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #282828<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint color-brand" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #D64220<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint color-light" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #8c8c8c<br>Contrast Ratio: 3.36:1</div>
  ```
***/
.color-box {
  align-items: center;
  border: 2px solid #dcdcdc;
  display: flex;
  float: left;
  height: 100px;
  justify-content: center;
  margin: 0 20px 20px 0;
  padding: 0 20px;
  width: calc(33.33% - 20px); }

.color-brand {
  color: #d64220; }

.color-light {
  color: #8c8c8c; }

/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/* 1 */
/* 1 */
/* 1 */
/* 1 */
/**

Variables
===

**/
/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
.applied-filters {
  width: 100%;
  border: none; }
  @media screen and (max-width: 989px) {
    .applied-filters {
      order: 1; } }
  @media print, screen and (min-width: 990px) {
    .applied-filters {
      border-top: 1px solid #dcdcdc;
      width: 99.7%; } }

@media screen and (max-width: 989px) {
  .collection-filters-wrap {
    border-top: none;
    padding-bottom: 0;
    position: fixed;
    bottom: 0;
    z-index: 3; }
    .collection-filters-wrap__container {
      width: 100%;
      padding: 0;
      display: flex;
      flex-flow: column;
      height: 100%; }
      @supports (padding-top: constant(safe-area-inset-top)) {
        .collection-filters-wrap__container {
          --safe-area-inset-top: constant(safe-area-inset-top);
          height: calc(100% + var(--safe-area-inset-top)); } }
      @supports (padding-top: env(safe-area-inset-top)) {
        .collection-filters-wrap__container {
          --safe-area-inset-top: env(safe-area-inset-top);
          height: calc(100% + var(--safe-area-inset-top)); } } }

@media screen and (max-width: 989px) {
  .collection-filters-container {
    order: 2; } }

.collection-filters {
  z-index: 4;
  display: flex;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 10px; }
  @media print, screen and (min-width: 650px) {
    .collection-filters {
      padding-left: 0.3%;
      padding-right: 0; } }
  @media print, screen and (min-width: 990px) {
    .collection-filters {
      width: 99.7%;
      margin-bottom: 0;
      justify-content: center; } }
  .collection-filters .icon {
    vertical-align: middle;
    margin-top: -0.08em; }
    @media print, screen and (min-width: 650px) {
      .collection-filters .icon {
        margin-right: 15px; } }
  .collection-filters .label {
    margin-left: .5em; }
  .collection-filters .btn-collection-filter {
    float: left;
    white-space: nowrap;
    padding: 0;
    margin-right: 35px;
    background: white;
    border: none;
    margin-left: 0;
    line-height: normal;
    cursor: pointer; }
    @media screen and (max-width: 350px) {
      .collection-filters .btn-collection-filter {
        margin-right: 30px; } }
    @media print, screen and (min-width: 650px) {
      .collection-filters .btn-collection-filter {
        float: none;
        margin-right: 15px; } }
    @media print, screen and (min-width: 990px) {
      .collection-filters .btn-collection-filter {
        margin-right: 30px; } }
    .collection-filters .btn-collection-filter:focus .button-inner__content, .collection-filters .btn-collection-filter.is-selected .button-inner__content, .collection-filters .btn-collection-filter:hover .button-inner__content, .collection-filters .btn-collection-filter:active .button-inner__content {
      color: #d64220;
      border-color: #d64220; }
    .collection-filters .btn-collection-filter .button-inner {
      text-align: left; }
      .collection-filters .btn-collection-filter .button-inner__text {
        display: none; }
        @media print, screen and (min-width: 650px) {
          .collection-filters .btn-collection-filter .button-inner__text {
            display: inline; } }
      .collection-filters .btn-collection-filter .button-inner__content {
        border-top: 5px solid transparent;
        padding: 20px 0px 15px 0px; }
        @media print, screen and (min-width: 990px) {
          .collection-filters .btn-collection-filter .button-inner__content {
            border-bottom: 5px solid transparent;
            border-top: none; } }
      @media print, screen and (min-width: 650px) {
        .collection-filters .btn-collection-filter .button-inner {
          text-align: center;
          margin-right: 10px; } }
    .collection-filters .btn-collection-filter--search {
      white-space: nowrap;
      float: right;
      margin-right: 0; }
      @media print, screen and (min-width: 650px) {
        .collection-filters .btn-collection-filter--search {
          float: none; } }
      .collection-filters .btn-collection-filter--search .button-inner {
        position: relative;
        padding-left: 35px;
        text-align: right; }
        @media print, screen and (min-width: 650px) {
          .collection-filters .btn-collection-filter--search .button-inner {
            text-align: center;
            padding-left: 15px; } }
        .collection-filters .btn-collection-filter--search .button-inner:before {
          display: block;
          left: 0;
          content: ' ';
          position: absolute;
          height: 1.75em;
          border-left: 1px solid #dcdcdc;
          top: 50%;
          transform: translateY(-35%); }
          @media print, screen and (min-width: 990px) {
            .collection-filters .btn-collection-filter--search .button-inner:before {
              transform: translateY(-50%); } }
        .collection-filters .btn-collection-filter--search .button-inner .collection-filter-icon {
          transform: scale(0.8); }
    @media screen and (max-width: 989px) {
      .collection-filters .btn-collection-filter--applied .button-inner__content {
        position: relative; }
        .collection-filters .btn-collection-filter--applied .button-inner__content:after {
          display: block;
          left: 23px;
          top: 13px;
          content: ' ';
          border-radius: 50%;
          position: absolute;
          background-color: #d64220;
          height: 8px;
          width: 8px; } }
    .collection-filters .btn-collection-filter .collection-filter-icon {
      font-size: 1.75em; }

@media screen and (max-width: 989px) {
  .collection-filters-set {
    border-bottom: 1px solid #dcdcdc; } }

@media print, screen and (min-width: 990px) {
  .collection-filters-set {
    height: 0;
    padding: 0; }
    .collection-filters-set--is-open {
      border-bottom: none;
      min-height: 90px;
      height: auto;
      padding-top: 40px;
      padding-bottom: 20px; } }

.lines_linearity-filter svg,
.lines_composition-filter svg {
  margin-right: 8px;
  margin-bottom: 3px; }

.color-filters-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 649px) {
    .color-filters-container {
      width: 400%;
      height: 55px;
      overflow-x: scroll;
      flex-wrap: nowrap;
      margin-bottom: 20px; } }
  .color-filters-container .btn-filter {
    flex-grow: 1;
    height: 100%;
    width: auto;
    min-width: auto;
    padding: 0;
    border: 3px solid transparent;
    outline: 0;
    margin: 0; }
    .color-filters-container .btn-filter:hover, .color-filters-container .btn-filter.is-applied {
      border-color: black; }
    @media print, screen and (min-width: 650px) {
      .color-filters-container .btn-filter {
        height: calc(100vw/20); } }
    @media print, screen and (min-width: 990px) {
      .color-filters-container .btn-filter {
        height: 55px; } }
    @media screen and (max-width: 989px) {
      .color-filters-container .btn-filter:last-of-type {
        margin-right: 25px; } }

.line-filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start; }
  .line-filters-container .btn-filter {
    height: 50px;
    min-height: 0;
    padding: 0 20px;
    margin: 0;
    margin-right: 10px;
    font-size: 17px;
    line-height: normal;
    text-transform: capitalize;
    margin: 0 12px 12px 0; }
    .line-filters-container .btn-filter:last-of-type {
      margin-right: 0; }
    .line-filters-container .btn-filter.filter-style-radio {
      margin-right: 0;
      border-right-width: 1px;
      border-left-width: 1px; }
      .line-filters-container .btn-filter.filter-style-radio:last-child {
        border-right-width: 2px; }
        @media screen and (max-width: 989px) {
          .line-filters-container .btn-filter.filter-style-radio:last-child {
            margin-right: 25px !important; } }
      .line-filters-container .btn-filter.filter-style-radio:first-child {
        border-left-width: 2px; }
    .line-filters-container .btn-filter.is-applied {
      background: black;
      border-color: black;
      color: white; }

.applied-filter-tags-container-wrap {
  *zoom: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px; }
  .applied-filter-tags-container-wrap:before, .applied-filter-tags-container-wrap:after {
    content: " ";
    display: table; }
  .applied-filter-tags-container-wrap:after {
    clear: both; }
  @media print, screen and (min-width: 650px) {
    .applied-filter-tags-container-wrap {
      display: flex;
      padding-bottom: 30px; } }
  @media print, screen and (min-width: 990px) {
    .applied-filter-tags-container-wrap {
      padding-bottom: 40px; } }
  .applied-filter-tags-container-wrap__count {
    width: 100%;
    margin-top: 10px; }
    @media print, screen and (min-width: 650px) {
      .applied-filter-tags-container-wrap__count {
        margin-top: 0px;
        width: auto;
        margin-left: auto; } }
  .applied-filter-tags-container-wrap .btn-filter-shuffle {
    display: none; }

.applied-filter-tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .applied-filter-tags-container.hidden {
    display: none; }

.applied-filter-tag {
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border: 2px solid #dcdcdc;
  border-radius: 25px;
  cursor: pointer;
  padding: 9px 16px;
  margin: 6px 12px 6px 0; }
  .applied-filter-tag .icon {
    align-self: center; }
  .applied-filter-tag .filter-tag-text {
    font-family: "Calibre", sans-serif;
    font-size: 18px;
    margin-right: 10px; }
  .applied-filter-tag .icon-cross-tag {
    width: 10px;
    height: 10px;
    margin: 0; }
  .applied-filter-tag .color-filter-icon {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    border: 0;
    margin-right: 10px; }
  .applied-filter-tag .collection-filter-icon {
    margin-right: 10px; }
