/**

Variables
===

**/
/***

Colors
===
Here are the colour combinations used by the site:

*Accessibility tip: Contrast ratio:*

Don't forget to run colour combinations of background color and text color
against a [contrast ratio checker](http://webaim.org/resources/contrastchecker/).
If the contrast ratio is too low, people _will not be able to read it_.

* WCAG level AA requires a contrast ratio of 4.5:1 for normal text and 3:1 for large text
* WCAG Level AAA requires a contrast ratio of 7:1 for normal text and 4.5:1 for large text

_Large text is defined as 18px+ (if bold) and or 24px+ if not bold._

  ```
  <div class="color-box font-smallprint" style="background-color: #dcdcdc; border-color: #000">Background: #dcdcdc<br>Foreground: #282828<br>Contrast Ratio: 10.75:1</div>
  <div class="color-box font-smallprint" style="background-color: #8c8c8c; color: #fff">Background: #8c8c8c<br>Foreground: #ffffff<br>Contrast Ratio: 3.36:1</div>
  <div class="color-box font-smallprint" style="background-color: #282828; color: #ffffff;">Background: #282828<br>Foreground: #ffffff<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint" style="background-color: #6e6e6e; color: #ffffff;">Background: #6e6e6e<br>Foreground: #ffffff<br>Contrast Ratio: 5.1:1</div>
  <div class="color-box font-smallprint" style="background-color: #f0f0f0;">Background: #f0f0f0<br>Foreground: #282828<br>Contrast Ratio: 12.94:1</div>
  <div class="color-box font-smallprint" style="background-color: #fafafa;">Background: #fafafa<br>Foreground: #282828<br>Contrast Ratio: 14.13:1</div>
  <div class="color-box font-smallprint" style="background-color: #D64220; color: #ffffff">Background: #D64220<br>Foreground: #ffffff<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint" style="background-color: #000000; color: #ffffff;">Background: #000000<br>Foreground: #ffffff<br>Contrast Ratio: 21:1</div>
  <div class="color-box font-smallprint" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #282828<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint color-brand" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #D64220<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint color-light" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #8c8c8c<br>Contrast Ratio: 3.36:1</div>
  ```
***/
.color-box {
  align-items: center;
  border: 2px solid #dcdcdc;
  display: flex;
  float: left;
  height: 100px;
  justify-content: center;
  margin: 0 20px 20px 0;
  padding: 0 20px;
  width: calc(33.33% - 20px); }

.color-brand {
  color: #d64220; }

.color-light {
  color: #8c8c8c; }

/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/* 1 */
/* 1 */
/* 1 */
/* 1 */
/**

Variables
===

**/
/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/**

Variables
===

**/
/***

Colors
===
Here are the colour combinations used by the site:

*Accessibility tip: Contrast ratio:*

Don't forget to run colour combinations of background color and text color
against a [contrast ratio checker](http://webaim.org/resources/contrastchecker/).
If the contrast ratio is too low, people _will not be able to read it_.

* WCAG level AA requires a contrast ratio of 4.5:1 for normal text and 3:1 for large text
* WCAG Level AAA requires a contrast ratio of 7:1 for normal text and 4.5:1 for large text

_Large text is defined as 18px+ (if bold) and or 24px+ if not bold._

  ```
  <div class="color-box font-smallprint" style="background-color: #dcdcdc; border-color: #000">Background: #dcdcdc<br>Foreground: #282828<br>Contrast Ratio: 10.75:1</div>
  <div class="color-box font-smallprint" style="background-color: #8c8c8c; color: #fff">Background: #8c8c8c<br>Foreground: #ffffff<br>Contrast Ratio: 3.36:1</div>
  <div class="color-box font-smallprint" style="background-color: #282828; color: #ffffff;">Background: #282828<br>Foreground: #ffffff<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint" style="background-color: #6e6e6e; color: #ffffff;">Background: #6e6e6e<br>Foreground: #ffffff<br>Contrast Ratio: 5.1:1</div>
  <div class="color-box font-smallprint" style="background-color: #f0f0f0;">Background: #f0f0f0<br>Foreground: #282828<br>Contrast Ratio: 12.94:1</div>
  <div class="color-box font-smallprint" style="background-color: #fafafa;">Background: #fafafa<br>Foreground: #282828<br>Contrast Ratio: 14.13:1</div>
  <div class="color-box font-smallprint" style="background-color: #D64220; color: #ffffff">Background: #D64220<br>Foreground: #ffffff<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint" style="background-color: #000000; color: #ffffff;">Background: #000000<br>Foreground: #ffffff<br>Contrast Ratio: 21:1</div>
  <div class="color-box font-smallprint" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #282828<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint color-brand" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #D64220<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint color-light" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #8c8c8c<br>Contrast Ratio: 3.36:1</div>
  ```
***/
.color-box {
  align-items: center;
  border: 2px solid #dcdcdc;
  display: flex;
  float: left;
  height: 100px;
  justify-content: center;
  margin: 0 20px 20px 0;
  padding: 0 20px;
  width: calc(33.33% - 20px); }

.color-brand {
  color: #d64220; }

.color-light {
  color: #8c8c8c; }

/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/* 1 */
/* 1 */
/* 1 */
/* 1 */
/**

Variables
===

**/
/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
.mobile-buttons-set {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
  bottom: 40px;
  white-space: nowrap; }
  .mobile-buttons-set .collection-search-icon {
    font-size: 1.1em;
    margin-right: 0; }

.btn-mobile {
  height: 50px;
  min-width: 50px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  background: white;
  cursor: pointer;
  margin-left: 1rem; }
  .btn-mobile:first-child {
    margin-left: 0; }

.btn-open-mobile-filters {
  padding: 0 25px; }

.mobile-panel-overlay,
.mobile-panel {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0; }
  .mobile-panel-overlay.mobile-search-panel,
  .mobile-panel.mobile-search-panel {
    bottom: auto;
    width: 100%; }
    .mobile-panel-overlay.mobile-search-panel .mobile-filters-section,
    .mobile-panel.mobile-search-panel .mobile-filters-section {
      padding-top: 0;
      padding-bottom: 0; }

.mobile-panel-overlay {
  min-width: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
  cursor: pointer; }

.mobile-panel {
  width: calc(100vw - 50px);
  background: white;
  z-index: 5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .mobile-panel .applied-filter-tags-container-wrap {
    padding: 25px;
    position: sticky;
    top: 0;
    background: #ffffff;
    box-shadow: 0px 1px 4px #dcdcdc;
    z-index: 10; }
    @media screen and (max-width: 649px) {
      .mobile-panel .applied-filter-tags-container-wrap {
        padding-bottom: .5rem; } }
  .mobile-panel .applied-filter-tags-container {
    padding: 0; }
  .mobile-panel .mobile-apply-button-placeholder {
    height: 85px; }
  .mobile-panel .mobile-apply-button-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 50px);
    padding: 10px;
    background: #ffffff; }
  .mobile-panel .mobile-apply-button {
    width: 100%; }

.mobile-filters-section {
  padding: 25px;
  text-align: left;
  clear: both; }
  .mobile-filters-section--scrollable {
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .mobile-filters-section--scrollable::-webkit-scrollbar {
      width: 0;
      height: 0; }
    @media screen and (max-width: 649px) {
      .mobile-filters-section--scrollable {
        padding-top: 15px; } }
    @media screen and (max-width: 989px) {
      .mobile-filters-section--scrollable {
        overflow-x: scroll; } }
  .mobile-filters-section form {
    margin-top: 0; }
  .mobile-filters-section .color-filters-container {
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .mobile-filters-section .color-filters-container::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .mobile-filters-section .line-filters-container {
    flex-direction: row;
    width: 300%; }
    @media print, screen and (min-width: 990px) {
      .mobile-filters-section .line-filters-container {
        flex-direction: column; } }
    @media print, screen and (min-width: 650px) {
      .mobile-filters-section .line-filters-container {
        width: 120%; } }
    @media print, screen and (min-width: 990px) {
      .mobile-filters-section .line-filters-container {
        width: 100%; } }
    .mobile-filters-section .line-filters-container .line-filters-group {
      display: flex;
      flex-direction: row; }
      @media print, screen and (min-width: 990px) {
        .mobile-filters-section .line-filters-container .line-filters-group {
          width: 100%; } }
      .mobile-filters-section .line-filters-container .line-filters-group .btn-filter {
        height: auto;
        min-height: 50px;
        min-width: 0;
        width: auto;
        padding: 10px 25px 10px 0; }
        @media print, screen and (min-width: 650px) {
          .mobile-filters-section .line-filters-container .line-filters-group .btn-filter {
            padding: 10px 15px 10px 0; } }
        .mobile-filters-section .line-filters-container .line-filters-group .btn-filter .collection-filter-icon {
          margin-left: 15px;
          margin-bottom: 4px; }
      .mobile-filters-section .line-filters-container .line-filters-group:first-of-type {
        margin-bottom: 10px;
        flex-wrap: nowrap;
        margin-right: 30px; }
        @media print, screen and (min-width: 650px) {
          .mobile-filters-section .line-filters-container .line-filters-group:first-of-type {
            margin-right: 50px; } }
        @media print, screen and (min-width: 990px) {
          .mobile-filters-section .line-filters-container .line-filters-group:first-of-type {
            flex-wrap: wrap; } }
        .mobile-filters-section .line-filters-container .line-filters-group:first-of-type .btn-filter {
          margin: 0 10px 10px 0; }
          @media print, screen and (min-width: 990px) {
            .mobile-filters-section .line-filters-container .line-filters-group:first-of-type .btn-filter:nth-of-type(2n) {
              margin-right: 0; } }
      .mobile-filters-section .line-filters-container .line-filters-group:last-of-type .btn-filter {
        width: calc(100%/3);
        border-width: 2px 1px;
        margin: 0; }
        .mobile-filters-section .line-filters-container .line-filters-group:last-of-type .btn-filter:first-of-type {
          border-left-width: 2px; }
        .mobile-filters-section .line-filters-container .line-filters-group:last-of-type .btn-filter:last-of-type {
          border-right-width: 2px; }
  .mobile-filters-section .mobile-filters-form .btn-panel-close {
    font-size: .75rem;
    padding: 0;
    border: none;
    width: auto;
    min-width: 1.5rem; }
    .mobile-filters-section .mobile-filters-form .btn-panel-close .icon {
      margin-right: 0; }

.mobile-filters-header {
  margin-top: 0;
  margin-bottom: 10px;
  width: 100%;
  text-align: left; }
  @media print, screen and (min-width: 650px) {
    .mobile-filters-header {
      display: none; } }
  .mobile-filters-header--scrollable {
    padding: 25px;
    padding-bottom: 0;
    margin-bottom: 0; }

.shaded-background {
  position: relative;
  overflow-x: hidden; }
  .shaded-background--header {
    position: inherit; }
  .shaded-background__tint {
    position: absolute;
    background-color: black;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0%;
    transition: opacity 0.5s ease; }
    @media screen and (max-width: 989px) {
      .shaded-background__tint {
        position: fixed;
        top: 0;
        height: 100vh; } }
    .shaded-background__tint--active {
      z-index: 1;
      opacity: 0.2; }
    .shaded-background__tint--higher {
      position: fixed;
      top: 0;
      height: 100vh;
      z-index: 4; }

.landing-page-header-wrap {
  overflow: hidden; }

.app-landing-page .landing-page-header {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  line-height: 40px;
  font-weight: normal;
  margin-top: 0px;
  color: white;
  height: 100%; }

.app-landing-page .landing-page-image {
  width: 100%;
  background-color: #ffffff; }

.app-landing-page .collection-filters-wrap {
  text-align: center;
  width: 100%;
  background-color: #ffffff; }
  .app-landing-page .collection-filters-wrap .btn-no-style {
    line-height: 2em;
    margin-top: 0; }
  @media print, screen and (min-width: 990px) {
    .app-landing-page .collection-filters-wrap {
      border-top: 1px #dcdcdc solid;
      position: relative;
      background: transparent; } }

@media print {
  .app-landing-page .mobile-buttons-set {
    display: none; } }

@keyframes opacity-animation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes opacity-animation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes opacity-animation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-o-keyframes opacity-animation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.o-hero {
  background-color: #855435;
  position: relative; }
  .o-hero:after {
    height: auto; }
  .o-hero--landing-page {
    overflow: hidden;
    max-height: 55vh; }
    @media print, screen and (min-width: 650px) {
      .o-hero--landing-page {
        max-height: 55vh; } }
    @media print, screen and (min-width: 990px) {
      .o-hero--landing-page {
        max-height: 70vh; } }
  .o-hero__container {
    z-index: 2; }
  .o-hero__supporting {
    position: absolute;
    bottom: 0;
    opacity: 1;
    transition: opacity 1s ease; }
    @media screen and (max-width: 989px) {
      .o-hero__supporting {
        line-height: 1.2; } }
    .o-hero__supporting--hidden {
      opacity: 0; }
  .o-hero__inner {
    z-index: 2; }
  .o-hero__image-wrapper {
    z-index: 1;
    width: 100%;
    height: 55vh;
    position: relative;
    overflow: hidden; }
    @media print, screen and (min-width: 990px) {
      .o-hero__image-wrapper {
        height: 70vh; } }
  .o-hero__image {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: transform 10s ease-in-out, opacity 500ms linear 9500ms;
    z-index: -1; }
    @media print, screen and (min-width: 650px) {
      .o-hero__image {
        height: auto;
        width: 100%; } }
    .o-hero__image--start {
      z-index: 0; }
    .o-hero__image--active {
      z-index: 1; }
    .o-hero__image--lazy {
      opacity: 1;
      z-index: 2; }
    .o-hero__image--0--start {
      transform: translate3d(8px, 105px, 0px) scale(1.3); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--0--start {
          transform: translate3d(55px, 100px, 0px) scale(1.55); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--0--start {
          transform: translate3d(15px, 150px, 0px) scale(1.6); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--0--start {
          transform: translate3d(15px, 20px, 0px) scale(1.5); } }
    .o-hero__image--0--active {
      transform: translate3d(-8px, 105px, 0px) scale(1.35); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--0--active {
          transform: translate3d(35px, 100px, 0px) scale(1.6); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--0--active {
          transform: translate3d(-15px, 140px, 0px) scale(1.65); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--0--active {
          transform: translate3d(-15px, 20px, 0px) scale(1.55); } }
    .o-hero__image--1--start {
      transform: translate3d(0px, 80px, 0px) scale(1.1); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--1--start {
          transform: translate3d(25px, 100px, 0px) scale(1.8); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--1--start {
          transform: translate3d(25px, 100px, 0px) scale(1.8); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--1--start {
          transform: translate3d(25px, -70px, 0px) scale(1.15); } }
    .o-hero__image--1--active {
      transform: translate3d(-10px, 80px, 0px) scale(1.1); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--1--active {
          transform: translate3d(0px, 100px, 0px) scale(1.8); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--1--active {
          transform: translate3d(0px, 100px, 0px) scale(1.8); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--1--active {
          transform: translate3d(-25px, -70px, 0px) scale(1.15); } }
    .o-hero__image--2--start {
      transform: translate3d(20px, 80px, 0px) scale(1.2); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--2--start {
          transform: translate3d(20px, 50px, 0px) scale(1.5); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--2--start {
          transform: translate3d(20px, 30px, 0px) scale(1.2); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--2--start {
          transform: translate3d(20px, -60px, 0px) scale(1.1); } }
    .o-hero__image--2--active {
      transform: translate3d(20px, 100px, 0px) scale(1.25); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--2--active {
          transform: translate3d(20px, 45px, 0px) scale(1.55); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--2--active {
          transform: translate3d(20px, 20px, 0px) scale(1.25); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--2--active {
          transform: translate3d(20px, -60px, 0px) scale(1.15); } }
    .o-hero__image--3--start {
      transform: translate3d(10px, 20px, 0px) scale(1.3); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--3--start {
          transform: translate3d(10px, 50px, 0px) scale(1.7); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--3--start {
          transform: translate3d(10px, -100px, 0px) scale(1.7); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--3--start {
          transform: translate3d(10px, -250px, 0px) scale(1.2); } }
    .o-hero__image--3--active {
      transform: translate3d(10px, 20px, 0px) scale(1.35); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--3--active {
          transform: translate3d(10px, 50px, 0px) scale(1.75); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--3--active {
          transform: translate3d(10px, -100px, 0px) scale(1.75); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--3--active {
          transform: translate3d(10px, -230px, 0px) scale(1.25); } }
    .o-hero__image--4--start {
      transform: translate3d(-10px, 0px, 0px) scale(1.65); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--4--start {
          transform: translate3d(0px, 50px, 0px) scale(1.85); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--4--start {
          transform: translate3d(0px, 100px, 0px) scale(1.45); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--4--start {
          transform: translate3d(0px, -105px, 0px) scale(1.4); } }
    .o-hero__image--4--active {
      transform: translate3d(-10px, -10px, 0px) scale(1.6); }
      @media print, screen and (min-width: 650px) {
        .o-hero__image--4--active {
          transform: translate3d(0px, 50px, 0px) scale(1.75); } }
      @media print, screen and (min-width: 990px) {
        .o-hero__image--4--active {
          transform: translate3d(0px, 100px, 0px) scale(1.4); } }
      @media screen and (min-width: 1200px) {
        .o-hero__image--4--active {
          transform: translate3d(0px, -80px, 0px) scale(1.3); } }
  .o-hero__video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background: #ffffff;
    overflow: hidden; }
    .o-hero__video-wrapper:after {
      content: ' '; }
  .o-hero__video {
    object-fit: fill;
    height: auto;
    width: 200%;
    transform: translateX(-25%); }
    @media print, screen and (min-width: 650px) {
      .o-hero__video {
        width: 110%;
        transform: translateX(-5%); } }
  .o-hero__copy {
    bottom: 19px;
    pointer-events: auto;
    position: absolute;
    z-index: 1; }
    @media print, screen and (min-width: 650px) {
      .o-hero__copy {
        bottom: 40px; } }
    @media print, screen and (min-width: 990px) {
      .o-hero__copy {
        bottom: 60px; } }
    @media print {
      .o-hero__copy {
        bottom: auto;
        position: relative; } }
  .o-hero__supporting {
    font-size: 28px; }
    @media print, screen and (min-width: 650px) {
      .o-hero__supporting {
        font-size: 32px !important; } }
  .o-hero__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0; }
