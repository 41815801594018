/**

Variables
===

**/
/***

Colors
===
Here are the colour combinations used by the site:

*Accessibility tip: Contrast ratio:*

Don't forget to run colour combinations of background color and text color
against a [contrast ratio checker](http://webaim.org/resources/contrastchecker/).
If the contrast ratio is too low, people _will not be able to read it_.

* WCAG level AA requires a contrast ratio of 4.5:1 for normal text and 3:1 for large text
* WCAG Level AAA requires a contrast ratio of 7:1 for normal text and 4.5:1 for large text

_Large text is defined as 18px+ (if bold) and or 24px+ if not bold._

  ```
  <div class="color-box font-smallprint" style="background-color: #dcdcdc; border-color: #000">Background: #dcdcdc<br>Foreground: #282828<br>Contrast Ratio: 10.75:1</div>
  <div class="color-box font-smallprint" style="background-color: #8c8c8c; color: #fff">Background: #8c8c8c<br>Foreground: #ffffff<br>Contrast Ratio: 3.36:1</div>
  <div class="color-box font-smallprint" style="background-color: #282828; color: #ffffff;">Background: #282828<br>Foreground: #ffffff<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint" style="background-color: #6e6e6e; color: #ffffff;">Background: #6e6e6e<br>Foreground: #ffffff<br>Contrast Ratio: 5.1:1</div>
  <div class="color-box font-smallprint" style="background-color: #f0f0f0;">Background: #f0f0f0<br>Foreground: #282828<br>Contrast Ratio: 12.94:1</div>
  <div class="color-box font-smallprint" style="background-color: #fafafa;">Background: #fafafa<br>Foreground: #282828<br>Contrast Ratio: 14.13:1</div>
  <div class="color-box font-smallprint" style="background-color: #D64220; color: #ffffff">Background: #D64220<br>Foreground: #ffffff<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint" style="background-color: #000000; color: #ffffff;">Background: #000000<br>Foreground: #ffffff<br>Contrast Ratio: 21:1</div>
  <div class="color-box font-smallprint" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #282828<br>Contrast Ratio: 14.74:1</div>
  <div class="color-box font-smallprint color-brand" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #D64220<br>Contrast Ratio: 4.51:1</div>
  <div class="color-box font-smallprint color-light" style="background-color: #ffffff;">Background: #ffffff<br>Foreground: #8c8c8c<br>Contrast Ratio: 3.36:1</div>
  ```
***/
.color-box {
  align-items: center;
  border: 2px solid #dcdcdc;
  display: flex;
  float: left;
  height: 100px;
  justify-content: center;
  margin: 0 20px 20px 0;
  padding: 0 20px;
  width: calc(33.33% - 20px); }

.color-brand {
  color: #d64220; }

.color-light {
  color: #8c8c8c; }

/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/* 1 */
/* 1 */
/* 1 */
/* 1 */
/**

Variables
===

**/
/**
 * Mixins
 */
/*
  @function get-breakpoint-directions

  Sorts through breakpoints SASS map,
  generates a full SASS map containing all the breakpoint
  variations we'll require

  Parameters:
  none
*/
/*
  @mixin breakpoint

  Inserts a media query

  Parameters:
  $name - name of breakpoint, choose from:

  1
  xsmall, small, medium, large, xlarge, xxlarge  - *just* that breakpoint
  2
  small-, medium-, large-, xlarge-, xxlarge-  - that breakpoint *and* below
  3
  xsmall+, small+, medium+, large+, xlarge+  - that breakpoint *and* up
  4
  Apply CSS upto the large+ breakpoint to also be Print CSS

  NB: the we're mobile up, so the minus values should be avoided..
*/
/*
  @function get-media

  Returns start and stop points of a given media query

  Parameters:
  $bp - the breakpoint you want the stop and stop points of
*/
/*
  @mixin placeholder

  Style form placeholder text
*/
/*
  @mixin styled-select

  https://github.com/filamentgroup/select-css

  Parameters:
  - none
*/
/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push

  Returns a margin-left and a calc() to correctly push
  a block a number of columns with breakpoint selection
  and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to push
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-multi

  A mix of @mixin push and @mixin column. Pass a map of the number
  of columns to push at each breakpoint.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to push on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include push-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin push-gutter

  Adds gutter margin to the sides passed to the set breakpoints.
  Defaults to left margin across all breakpoints.

  Parameters:
  $sides - the sides you'd like to apply margin to
  $bps - at which breakpoints

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container

  Sets up columns container

  Parameters:
  none

  ```scss
    .list-articles {
    @include columns-container;
  }
  ```
*/
/*
  @mixin column

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
  @mixin columns-container-flex

  Sets up columns container - flex version

  Parameters:
  none

  ```scss
  .list-articles {
    @include columns-container-flex;
  }
  ```
*/
/*
  @mixin column-flex

  Sets up single column

  Parameters:
  $colspans - scss map detailing how many design columns this column needs to span on each breakpoint

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include column-flex($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/
/*
.block {
  @include serif(18, 25, 500, italic);

  @include breakpoint(large) {
    font-size: 25px;
    line-height: 35px;
  }
}
*/
/*
  @mixin font_smoothing

  Set font smoothing ON or OFF
*/
/*
  @mixin text-underline

  Inserts vendor prefixed box shadows

  Parameters:
  $off - color
  $on - color for hover
  $pos - vertical position
*/
/*
  @mixin hide_text

  Hides text in an element
*/
/**
 * Tint
 * An automatic tint which allows the white copy above the image to be legible
 */
/* Animation for mobile dropdown. */
.dropdown-slide-enter {
  transform: translateX(100%); }
  .dropdown-slide-enter.dropdown-slide-enter-active {
    transform: translateX(0%);
    transition: transform 900ms ease 50ms; }

.dropdown-slide-leave {
  transform: translateX(0%); }
  .dropdown-slide-leave.dropdown-slide-leave-active {
    transform: translateX(100%);
    transition: transform 900ms ease 50ms; }

.dropdowns-menu {
  text-align: left; }
  @media screen and (max-width: 989px) {
    .dropdowns-menu {
      margin-top: 30px;
      display: flex;
      flex-direction: column; }
      .dropdowns-menu .dropdowns-menu__button {
        border-top: 1px solid #dcdcdc;
        border-left: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc;
        margin-right: 0;
        padding: 20px 15px;
        background-color: #ffffff; }
        .dropdowns-menu .dropdowns-menu__button--last {
          border-bottom: 1px solid #dcdcdc; }
      .dropdowns-menu .dropdowns-menu__icon {
        top: 20px;
        right: 22px;
        font-size: 19px;
        transform: rotate(-90deg);
        display: inline; } }
  .dropdowns-menu__button-wrapper {
    display: inline; }
    @media print, screen and (min-width: 990px) {
      .dropdowns-menu__button-wrapper {
        position: relative; } }
  .dropdowns-menu__button {
    color: #282828;
    font-weight: bold;
    font-size: 21px;
    position: relative;
    padding-top: 30px;
    text-align: left;
    margin-right: 40px;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 100%; }
    @media print, screen and (min-width: 990px) {
      .dropdowns-menu__button {
        width: auto; } }
    @media print, screen and (min-width: 990px) {
      .dropdowns-menu__button--last {
        padding-left: 30px; }
        .dropdowns-menu__button--last:before {
          display: block;
          left: 0;
          content: ' ';
          position: absolute;
          height: 1.75em;
          border-left: 1px solid #b4b4b4;
          transform: translateY(-50%);
          top: 80%; } }
  .dropdowns-menu__button-content {
    font-family: "Calibre", sans-serif;
    margin-right: 5px; }
  .dropdowns-menu__button-sup {
    color: #d64220;
    font-size: 12px; }
    @media print, screen and (min-width: 990px) {
      .dropdowns-menu__button-sup {
        display: none; } }
  .dropdowns-menu__icon {
    position: absolute;
    top: 33px;
    font-size: 21px; }
    .dropdowns-menu__icon--active {
      transform: rotate(-180deg); }
    .dropdowns-menu__icon--last {
      display: none; }

.dropdown {
  cursor: default;
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7; }
  @media screen and (max-width: 989px) {
    .dropdown {
      display: grid;
      grid-template-rows: 60px 1fr; } }
  @media screen and (max-width: 989px) {
    .dropdown {
      top: 0;
      left: 0; }
      .dropdown .dropdown__content {
        scrollbar-width: none;
        -ms-overflow-style: none; }
        .dropdown .dropdown__content::-webkit-scrollbar {
          width: 0;
          height: 0; }
      .dropdown .dropdown__body-wrapper {
        scrollbar-width: none;
        -ms-overflow-style: none; }
        .dropdown .dropdown__body-wrapper::-webkit-scrollbar {
          width: 0;
          height: 0; } }
  .dropdown--scroll {
    overflow: hidden; }
    @media print, screen and (min-width: 990px) {
      .dropdown--scroll {
        overflow: scroll !important;
        overflow-y: scroll; }
        .dropdown--scroll::-webkit-scrollbar {
          width: 22px; }
        .dropdown--scroll::-webkit-scrollbar-thumb {
          border-radius: 10px;
          min-height: 120px;
          background-color: #dcdcdc;
          border: solid 8px white; } }
    @media print, screen and (min-width: 990px) {
      .dropdown--scroll .dropdown__icon {
        right: -10px; } }
  .dropdown--quick-scroll .dropdown__content {
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .dropdown--quick-scroll .dropdown__content::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .dropdown--quick-scroll .dropdown__body-wrapper {
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .dropdown--quick-scroll .dropdown__body-wrapper::-webkit-scrollbar {
      width: 0;
      height: 0; }
  @media print, screen and (min-width: 990px) {
    .dropdown {
      left: 0px;
      top: 40px;
      width: 210px;
      height: 280px;
      background-color: white;
      border: 2px solid #dcdcdc;
      padding: 10px 20px; } }
  .dropdown__body-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll; }
    @media print, screen and (min-width: 990px) {
      .dropdown__body-wrapper {
        height: 80%;
        overflow-y: initial; } }
  .dropdown__header-text {
    font-size: 21px; }
    @media screen and (max-width: 989px) {
      .dropdown__header-text {
        margin-left: -20px; } }
  .dropdown__header {
    cursor: pointer;
    background-color: #f7f7f7;
    position: relative;
    width: 100%;
    z-index: 2;
    padding: 15px 40px 15px 40px;
    border-bottom: 1px solid #dcdcdc; }
    @media print, screen and (min-width: 990px) {
      .dropdown__header {
        cursor: default;
        display: block;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        font-size: 25px;
        background-color: #ffffff;
        position: relative;
        border-bottom: none;
        margin-left: auto;
        padding: 0;
        margin-top: 0;
        margin-bottom: 5px; }
        .dropdown__header--text {
          font-size: 25px;
          margin-top: 0;
          margin-bottom: 15px; } }
  .dropdown__icon {
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 12px; }
    @media print, screen and (min-width: 990px) {
      .dropdown__icon {
        right: 0px;
        top: 3px; } }
    .dropdown__icon:hover {
      cursor: pointer; }
    .dropdown__icon--x {
      display: none; }
      @media print, screen and (min-width: 990px) {
        .dropdown__icon--x {
          display: inline;
          top: 15px;
          right: 8px;
          font-size: 11px; } }
    .dropdown__icon--back {
      top: 3px;
      right: 15px;
      display: inline-block;
      font-size: 19px;
      position: relative;
      transform: rotate(90deg);
      margin-right: 10px; }
      @media print, screen and (min-width: 990px) {
        .dropdown__icon--back {
          display: none; } }
    .dropdown__icon--artist {
      position: relative;
      margin-bottom: 2px;
      margin-left: 5px; }
      @media screen and (max-width: 989px) {
        .dropdown__icon--artist {
          position: absolute;
          right: 10px;
          top: 13px;
          margin-left: 0px; } }
      @media screen and (max-width: 649px) {
        .dropdown__icon--artist {
          right: 0px; } }
  .dropdown__content {
    font-weight: normal;
    font-size: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 10px;
    padding: 0px 0px 15px 20px; }
    @media print, screen and (min-width: 990px) {
      .dropdown__content {
        overflow-x: initial;
        overflow-y: initial;
        padding: 0;
        margin-top: 0;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100%; } }
  .dropdown__list {
    margin: 0; }
  .dropdown__list-item {
    position: relative;
    margin: 0;
    line-height: 40px; }
    @media print, screen and (min-width: 990px) {
      .dropdown__list-item {
        line-height: 30px; } }
    .dropdown__list-item--active {
      color: #d64220; }
    .dropdown__list-item:hover {
      cursor: pointer; }
      @media print, screen and (min-width: 990px) {
        .dropdown__list-item:hover {
          color: #d64220; } }
